//
// Color Groups

.color-group {
	margin-top: 1rem;
	overflow: hidden;

	@include breakpoint($screen__m) {
		grid-area: color-group;
		margin-bottom: rem-calc(8);
		margin-top: 0;
	}

	.list {
		display: flex;
		gap: 1rem;
		overflow: scroll hidden;
		padding: 0 1rem;
		white-space: nowrap;

		@include breakpoint($screen__m) {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			padding: 0;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.item {
		align-items: flex-end;
		background: revert;
		border-bottom: rem-calc(2) solid transparent;
		display: flex;
		margin-bottom: 0;
		padding-bottom: rem-calc(24);
		white-space: nowrap;

		@include breakpoint($screen__m) {
			border-width: rem-calc(1.5);
			display: inline-block;
			padding-bottom: rem-calc(14);

			img {
				max-width: 100% !important;
			}
		}

		&.current {
			border-bottom-color: $dark-gray;
		}

		img {
			max-width: rem-calc(143);
		}
	}
}
