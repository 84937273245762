// import Foundation vars
@import "settings";

///
/// MAGENTO
///
@import "mixins/media-queries";
@import "vendor/magento-ui/lib";
@import "vendor/magento-ui/variables/typography";
// Theme Vars
@import "variables";
// Custom Reset
//@import "blocks/reset";
// Theme Blocks
//@import "blocks/actions-toolbar";
//@import "blocks/breadcrumbs";
//@import "blocks/buttons";
@import "blocks/extends";

//@import "blocks/forms";

$form-field__vertical-indent__desktop: 30px !default;
$form-calendar-icon__color: $primary-color !default;

@import "blocks/icons";
@import "blocks/layout";
@import "blocks/loaders";
@import "blocks/messages";
@import "blocks/navigation";
@import "blocks/pages";
@import "blocks/popups";
@import "blocks/price";
@import "blocks/sections";
@import "blocks/tables";
@import "blocks/tooltips";
// Modal / Sliding Panel
@import "blocks/components/modals";
// lib
@import "blocks/components/modals_extend";
// local
// Modules
@import "../Magento_AdvancedCheckout/styles/module";
@import "../Magento_Braintree/styles/module";
@import "../Magento_Bundle/styles/module";
@import "../Magento_Catalog/styles/module";
@import "../Magento_CatalogEvent/styles/module";
@import "../Lfi_CatalogInventory/styles/module";
@import "../Magento_CatalogSearch/styles/module";
@import "../Magento_Checkout/styles/module";
@import "../Magento_Customer/styles/module";
@import "../Magento_Downloadable/styles/module";
//@import "../Magento_GiftCard/styles/module";
//@import "../Magento_GiftCardAccount/styles/module";
//@import "../Magento_GiftMessage/styles/module";
//@import "../Magento_GiftRegistry/styles/module";
//@import "../Magento_GiftWrapping/styles/module";
@import "../Magento_GroupedProduct/styles/module";
//@import "../Magento_Invitation/styles/module";
@import "../Magento_LayeredNavigation/styles/module";
@import "../Magento_Msrp/styles/module";
//@import "../Magento_MultipleWishlist/styles/module";
@import "../Magento_Multishipping/styles/module";
@import "../Magento_Newsletter/styles/module";
@import "../Magento_Paypal/styles/module";
@import "../Magento_ProductVideo/styles/module";
@import "../Magento_Review/styles/module";
@import "../Magento_Reward/styles/module";
@import "../Magento_Rma/styles/module";
@import "../Magento_SalesRule/styles/module";
@import "../Magento_SendFriend/styles/module";
@import "../Magento_Swatches/styles/swatches";
//@import "../Magento_Theme/styles/module";
@import "../Magento_Vault/styles/module";
@import "../Magento_Weee/styles/module";
//@import "../Magento_Wishlist/styles/module";
@import "../Lfi_CustomerBalance/styles/module";
@import "../ErgonTech_Mage2LayerApi/styles/module";
// Widgets
@import "../Magento_AdvancedCheckout/styles/widgets";
@import "../Magento_Banner/styles/widgets";
@import "../Magento_Catalog/styles/widgets";
@import "../Magento_CatalogEvent/styles/widgets";
@import "../Magento_Cms/styles/widgets";
@import "../Magento_MultipleWishlist/styles/widgets";
@import "../Magento_VersionsCms/styles/widgets";
