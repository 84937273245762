//
// Cross Sell List

.cross-sell-carousel-products-container {
	.skeleton-image {
		min-width: unset;
	}

	.skeleton-button {
		max-width: 100px;
	}
}

//
// Upsell List
.block-upsell {
	.skeleton-item {
		margin-left: 12px;
		margin-right: 12px;
	}

	.skeleton-image {
		min-height: 172px;
		min-width: 172px;
	}

	.skeleton-text-field {
		margin-bottom: 8px;
	}
}
