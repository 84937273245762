.cart-upsell-carousel-products-container {
	--newleaf-slider-peek-length: 21px;
	margin: 0 -15px !important;

	@include media-breakpoint-up(xl) {
		--newleaf-slider-peek-length: 0;
		margin: 0 6px !important;
		// override evo styles
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.newleaf-slider-card-evo {
		--newleaf-slide-snap-align: start;
		// custom breakpoint requested by Melanie for this cart carousel
		@media screen and (min-width: 380px) {
			--newleaf-slider-slides-visible: 2;
		}
	}

	.newleaf-slider-card-evo-title {
		@include media-breakpoint-up(md) {
			padding-top: 8px !important;
		}
	}

	.card-evo {
		border: 1px solid map-get($lfi-palette, light-gray);
	}

	.card-evo-content {
		--gap: 0;
		height: 100%;
	}

	.card-evo-product-information {
		--gap: 0;
	}

	.card-evo-product-name {
		color: map-get($lfi-palette, jet);
	}

	.cart-evo-image {
		margin-bottom: 24px;
	}

	.card-evo-product-subtitle {
		color: map-get($lfi-palette, jet);
		margin-bottom: 8px;
	}

	.card-evo-product-content {
		--card-evo-content-padding: 14px 14px 22px;

		@include media-breakpoint-up(tablet) {
			--card-evo-content-padding: 20px 20px 28px;
		}
	}

	.quick-add-to-cart-list-item {
		min-width: unset;

		@include media-breakpoint-up(tablet) {
			padding-left: 18px;
			padding-right: 18px;
		}
	}

	.card-evo-product-price {
		margin-bottom: 16px;
	}

	.card-evo-actions {
		margin-top: auto;
	}

	h6 {
		@include bold-text;
		margin-bottom: 4px;
	}
}
