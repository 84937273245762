//
// Size Guide - Size Chart
//

.size-chart-pane {
	text-align: left;
}

.size-chart-container {
	.form-check {
		margin: 24px 0;
	}

	.form-switch {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
	}

	.form-check-input {
		flex: initial;
		float: none;
		height: 1rem;
		margin: 2px 12px;

		&::before {
			display: none;
		}

		&:checked {
			// override way to general input styles
			// @todo - see about strategy to address general input styles
			background-color: $form-check-input-checked-bg-color;
			background-image: escape-svg($form-switch-checked-bg-image);
			background-repeat: no-repeat;
			border-color: $form-check-input-checked-border-color;
		}
	}

	.form-check-label {
		@include bold-text;
		line-height: 20px;
		margin: 0;
	}
}

.size-chart-table-wrapper {
	margin-bottom: 12px;
	overflow: hidden;
	position: relative;

	&:after {
		/* stylelint-disable-next-line */
		box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.4);
		content: '';
		display: inline-block;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 0;
	}
}

.size-chart-table {

	.table {
		margin-bottom: 0;
	}

	tr > th:first-child,
	tr > td:first-child {
		left: 0;
		position: sticky;

		&:after {
			/* stylelint-disable-next-line */
			background: linear-gradient(281deg, rgba(43, 42, 39, 0.15) 5.7%, rgba(43, 42, 39, 0.00) 49.95%);
			content: '';
			height: 100%;
			width: 27px;
		}
	}

	th,
	td {
		min-width: 80px;
	}

	caption {
		display: none;
	}
}

.sizing-labels {
	&.active {
		.cm {
			display: inline-block;
		}

		.in {
			display: none;
		}
	}

	.cm {
		display: none;
	}
}
