//
// Quick Add Widget
//

.quick-add-modal {
	.modal-content {
		background: map-get($lfi-palette, snow);
		max-width: none !important;
	}

	.quick-add-to-cart {
		@extend %quick-add-action-icon;
	}
}

.quick-add-modal-body {
	h4 {
		margin-bottom: 32px;
		text-align: left;
	}
}

.quick-add-item-wrapper {
	display: flex;
}

.quick-add-items {
	@include list-reset;
}

.quick-add-item {
	margin: 0;
	padding: rem-calc(30 8);

	@include breakpoint(medium) {
		border-bottom: rem-calc(1) solid map-get($lfi-palette, light-gray);
		padding: rem-calc(40 0 14 0);
	}

	&:first-of-type {
		border-bottom: rem-calc(1) solid map-get($lfi-palette, light-gray);
		padding-top: 0;
	}

	&:last-of-type {
		border-bottom-style: none;
	}

	&:nth-of-type(n+3) {
		@include breakpoint(small down) {
			display: none;
		}
	}
}

.quick-add-to-carts {
	@extend %quick-add-action-icon;
}

.quick-add-item-image {
	flex: 0 0 rem-calc(64);
	margin-right: rem-calc(16);

	@include breakpoint(medium) {
		flex: 0 0 rem-calc(88);
		margin-right: rem-calc(24);
	}
}

.quick-add-item-content {
	flex: 1 1 auto;
	margin-right: rem-calc(8);
	text-align: left;

	@include breakpoint(medium) {
		margin-right: rem-calc(24);
	}

	.price-box {
		justify-content: flex-start;
	}

	[class$="-price"] {
		padding: 0;
	}

	a {
		color: $base-color;
	}

	h5 {
		@include bold-text;
		font-size: rem-calc(16);
		margin-bottom: 0;
	}

	p {
		line-height: rem-calc(20);
		margin-bottom: rem-calc(2);
	}
}
