// Size Measure Guide Styles
// ----------------

//
// Measure Guide
//
.measure-guide-container {
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(2, auto);

	@include media-breakpoint-up(md) {
		grid-template-columns: auto minmax(auto, 255px);
	}
}

.measure-guide-instructions {
	@include list-reset;
	// override core modal style
	text-align: left !important;

	@include media-breakpoint-up(md) {
		margin: auto;
	}

	.header {
		@include bold-text;
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			font-size: 18px;
			line-height: 22px;
		}

		span {
			background: map-get($lfi-palette, jet);
			border-radius: 50%;
			color: $white;
			display: inline-block;
			font-family: $header-font-family;
			font-size: 14px;
			line-height: 1;
			margin-right: 6px;
			min-height: 16px;
			min-width: 16px;
			text-align: center;
		}
	}

	p {
		line-height: 20px;
	}

	li {
		margin-bottom: 16px;

		@include media-breakpoint-up(md) {
			margin-bottom: 24px;
		}
	}
}

.measure-guide-silhouette {
	align-items: center;
	background: $background-secondary;
	border-radius: 6px;
	display: grid;
	justify-content: center;
	padding: 23px 8px;

	@include media-breakpoint-up(md) {
		padding: 51px 8px;
	}

	img {
		max-width: 145px;
		width: 100%;
	}
}
