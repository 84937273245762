// Custom Vars

$max-page-width: rem-calc(1440);
$form-text-input-height: rem-calc(50);
$form-input-margin-bottom: rem-calc(15);
$input-color-disabled: rgba(0, 0, 0, 0.75);
$input-border: rem-calc(1) solid $base-color;
$popup-boxshadow: rem-calc(0 20 40 0) rgba(0, 0, 0, 0.5);
$popup-radius: rem-calc(20);
$body-text-hover-color: map-get($lfi-palette, jet-hover);
$filter-option-hover-color: scale-color($background-primary-color, $lightness: 20%);

$pdp-shopbox-bg: map-get($lfi-palette, snow);

$plp-gutters-mobile: rem-calc(10);
$plp-gutters-desktop: rem-calc(14);
$plp-grid-margins-desktop: rem-calc(25);

// Input button adjustment mixin vars
$input-button-color-adjusted: map-get($lfi-palette, jet);
$input-button-input-text-color-adjusted: map-get($lfi-palette, jet);
$input-button-text-color-adjusted: $white;

$registration-padding-small: rem-calc(70 30);
$registration-padding-large: rem-calc(70 50);

/* stylelint-disable-next-line */
$modal-box-shadow: rem-calc(0 20 40 0) rgba(0, 0, 0, 0.5);
$modal-overlay-filter: blur(rem-calc(4));
$modal-inner-padding: rem-calc(32);
$modal-inner-padding-desktop: rem-calc(48);
$account-grid-column-gap: rem-calc(88);
$account-grid-max-width: rem-calc(632);

// Yotpo theme vars
$yotpo-score-font: $body-font-family;
$yotpo-desktop-breakpoint: 768px;

///
// Bootstrap/Foundation compat
///

// assign bootstrap's button disabled opacity to foundation's value
$btn-disabled-opacity: $button-opacity-disabled;
